<template>
    <div>
        <!-- start -->
        <div v-if="core.marketing_banner_src.length > 0" class="mb-4 d-flex justify-content-center">
            <b-img :src="core.marketing_banner_src" fluid alt="Responsive image"></b-img>
        </div>
        <b-card style="" v-if="loggedIn_user_role == 1">
            <div class="d-flex justify-content-between">
                <button type="button" class="btn btn-primary mb-2 mr-2 float-left" @click="resetRepData">
                    <i class="mdi mdi-arrow-left mr-1"></i> Go back
                </button>
            </div>
        </b-card>
        <!-- end -->
        <!-- start -->
        <!-- <b-card style="" v-if="selectedFiltered_rep_details"> -->
        <b-card style="" v-if="selected_rep_details">
            <h3>
                {{ selected_rep_details.name }}
            </h3>
            {{ selected_rep_details.email }}
        </b-card>

        <!-- end -->

        <b-card>
            <div class="d-flex justify-content-between mt-2">
                <div></div>
                <div>
                    <button v-if="loggedIn_user_role == 1" type="button" class="btn btn-danger mb-2 mr-2 float-left" @click="showAssignClientModal">
                        <i class="mdi mdi-plus mr-1"></i> Assign a client
                    </button>
                    <button type="button" class="btn btn-success mb-2 mr-2 float-left" @click="createClient">
                        <i class="mdi mdi-plus mr-1"></i> Add a client
                    </button>
                </div>
            </div>
            <div class="d-flex justify-content-between"></div>
            <b-tabs>
                <b-tab title="Linked Clients" active class="primary">
                    <simpleLayout :items_count="10">
                        <template #content>
                            <repsLinkedClientsTable />
                        </template>
                    </simpleLayout>
                </b-tab>
            </b-tabs>
        </b-card>
        <!-- end -->
        <assignClientModal v-if="show_modal" />
        <repCreateClientModal v-if="show_modal_createClient" />
    </div>
</template>

<script>
import { adminComputed, repMethods, repComputed } from "@/state/helpers";
import simpleLayout from "@/components/layout-templates/simple-layout.vue";
import repsLinkedClientsTable from "./repsLinkedClientsTable.vue";
import assignClientModal from "./repsAssignClientModal.vue";
import repCreateClientModal from "./repCreateClientModal.vue";

export default {
    components: {
        simpleLayout,
        repsLinkedClientsTable,
        assignClientModal,
        repCreateClientModal,
    },
    data: () => ({
        show_modal: false,
        show_modal_createClient: false,
        ready: false,
        repDetails: {
            id: "",
            name: "",
            email: "",
        },
    }),
    computed: {
        ...adminComputed,
        ...repComputed,
    },
    methods: {
        ...repMethods,
        resetRepData() {
            this.setSelectedRepId(-1);
            // this.$router.push({ name: "reps" });
            //go back to prev route
            this.$router.back();
        },
        showAssignClientModal() {
            this.show_modal = true;
            this.$nextTick(() => {
                this.$bvModal.show("assignClientModal");
                this.$root.$once("bv::modal::hidden", (event) => {
                    if (event.type == "hidden" && event.componentId == "assignClientModal") {
                        this.show_modal = false;
                        this.edit_id = -1;
                    }
                });
            });
        },
        loadRepDetails() {
            const urlParams = this.$route.params.repid;

            if (this.setSelectedRepId(urlParams)) {
                this.getSelectedRepDetails(urlParams).then(() => {});
                // this.$router.push({
                //         path: `/reps/view/client/${this.loggedIn_user_id}`,
                //         param: { ${this.loggedIn_user_id} },
                //     });
            }
            this.setFilteredSelectedRepDetails();
        },
        createClient() {
            this.show_modal_createClient = true;
            this.$nextTick(() => {
                this.$bvModal.show("repCreateclientModal");
                this.$root.$once("bv::modal::hidden", (event) => {
                    if (event.type == "hidden" && event.componentId == "repCreateclientModal") {
                        this.show_modal_createClient = false;
                    }
                });
            });
        },
    },
    mounted() {
        //get the userId from the url
        // this.setSelectedRepId(this.$route.params.repid);

        // if (this.selected_rep_id !== -1) {
        //     this.setSelectedRepDetails(this.$route.params.repid).then(() => {
        //         //
        //     });
        //     this.setFilteredSelectedRepDetails();
        // }
        if (this.all_reps.length == 0) {
            this.setRepUsers().then(() => {
                this.loadRepDetails();
            });
        } else {
            this.loadRepDetails();
        }
    },
};
</script>

<style scope></style>
