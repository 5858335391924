<template>
    <b-overlay :show="busySaving">
        <b-form @submit.prevent="">
            <b-row class="mb-2">
                <b-col cols="12" md="6">
                    <label class="mb-0" for="">Company name:</label>
                    <b-form-input
                        id="company_name"
                        size="sm"
                        type="text"
                        v-model="$v.form.company_name.$model"
                        :state="validateState('company_name')"
                        aria-describedby="company_name-live-feedback"
                    ></b-form-input>
                    <b-form-invalid-feedback v-if="formSubmit" id="ccompany_name-1-live-feedback"
                        >This is a required field and must be at least 3 characters.</b-form-invalid-feedback
                    >
                </b-col>
                <b-col cols="12" md="6">
                    <label class="mb-0" for="">Contact Person:</label>
                    <b-form-input
                        id="contact_person"
                        size="sm"
                        type="text"
                        v-model="$v.form.contact_person.$model"
                        :state="validateState('contact_person')"
                        aria-describedby="contact-person-live-feedback"
                    ></b-form-input>
                    <b-form-invalid-feedback v-if="formSubmit" id="contact-person-1-live-feedback"
                        >This is a required field and must be at least 3 characters.</b-form-invalid-feedback
                    >
                </b-col>
            </b-row>
            <b-row class="mb-2">
                <b-col cols="12" md="6">
                    <label class="mb-0" for="">Contact No:</label>
                    <b-form-input
                        id="contact-no"
                        size="sm"
                        type="text"
                        v-model="$v.form.contact_no.$model"
                        :state="validateState('contact_no')"
                        aria-describedby="contact-no-live-feedback"
                    ></b-form-input>
                    <b-form-invalid-feedback v-if="formSubmit" id="contact-no-1-live-feedback"
                        >This is a required field and must be at least 3 characters.</b-form-invalid-feedback
                    >
                </b-col>
                <b-col cols="12" md="6">
                    <label class="mb-0" for="">Company Reg:</label>
                    <b-form-input v-model="form.company_reg" size="sm" type="text"></b-form-input>
                </b-col>
            </b-row>
            <b-row class="mb-2">
                <b-col cols="12" md="6">
                    <label class="mb-0" for="">VAT No:</label>
                    <b-form-input v-model="form.vat_no" size="sm" type="text"></b-form-input>
                </b-col>
                <b-col cols="12" md="6">
                    <label class="mb-0" for="">Customer No:</label>
                    <b-form-input v-model="form.customer_no" size="sm" type="text"></b-form-input>
                </b-col>
            </b-row>
            <b-row class="mb-1">
                <b-col cols="12" md="6">
                    <label class="mb-0" for="">Email:</label>
                    <b-form-input size="sm" type="text" v-model="$v.form.email.$model" :state="validateState('email')"></b-form-input>
                    <b-form-invalid-feedback v-if="formSubmit" id="client-type-live-feedback">This is a required field.</b-form-invalid-feedback>
                </b-col>
                <b-col cols="12" md="6">
                    <label class="mb-0" for="">Client Type:</label>
                    <b-form-select
                        id="client-type"
                        name="client-type"
                        v-model="$v.form.type.$model"
                        :options="client_type_select_options"
                        :state="validateState('type')"
                        aria-describedby="client-type-live-feedback"
                        size="sm"
                    ></b-form-select>
                    <b-form-invalid-feedback v-if="formSubmit" id="client-type-live-feedback">This is a required field.</b-form-invalid-feedback>
                </b-col>
            </b-row>
            <!-- address section -->
            <b-row class="mb-2">
                <b-col cols="12" md="4">
                    <label class="mb-0" for="">City:</label>
                    <b-form-input
                        id="contact-no"
                        size="sm"
                        type="text"
                        v-model="$v.form.city.$model"
                        :state="validateState('city')"
                        aria-describedby="contact-no-live-feedback"
                    ></b-form-input>
                    <b-form-invalid-feedback v-if="formSubmit" id="contact-no-1-live-feedback"
                        >This is a required field and must be at least 3 characters.</b-form-invalid-feedback
                    >
                </b-col>
                <b-col cols="12" md="4">
                    <label class="mb-0" for="">Postal Code:</label>
                    <b-form-input v-model="$v.form.postal_code.$model" size="sm" type="text" :state="validateState('postal_code')"></b-form-input>
                    <b-form-invalid-feedback v-if="formSubmit" id="ccompany_name-1-live-feedback"
                        >This is a required field and must be at least 3 characters.</b-form-invalid-feedback
                    >
                </b-col>
                <b-col cols="12" md="4">
                    <label class="mb-0" for="">Province:</label>
                    <b-form-select
                        id="client-type"
                        name="client-type"
                        v-model="$v.form.province.$model"
                        :options="client_province_select_options"
                        :state="validateState('province')"
                        aria-describedby="client-type-live-feedback"
                        size="sm"
                    ></b-form-select>
                </b-col>
            </b-row>
            <b-row class="mb-2">
                <b-col>
                    <label class="mb-0" for="">Address:</label>
                    <b-form-textarea v-model="form.address" id="textarea" placeholder="Address" rows="3" max-rows="6"></b-form-textarea>
                </b-col>
            </b-row>
            <div class="modal-footer">
                <div class="d-flex justify-content-between mt-2"></div>
                <b-button v-if="!edit" type="submit" variant="success" @click="createClientForm">Submit</b-button>
                <b-button v-if="edit" type="submit" variant="success">update</b-button>
                <button type="button" class="btn btn-danger" @click="closeModal()">Close</button>
            </div>
        </b-form>
    </b-overlay>
</template>

<script>
//eslint-disable-next-line
import Swal from "sweetalert2";
import { validationMixin } from "vuelidate";
//eslint-disable-next-line
import { required, email, minLength } from "vuelidate/lib/validators";
import { repMethods, repComputed } from "@/state/helpers";

export default {
    mixins: [validationMixin],
    props: {
        edit: {
            Boolean,
            required: false,
        },
        clientId: { Number },
    },
    data: () => ({
        formSubmit: false,
        busySaving: false,
        // form2: {
        //         "company_name": "diversitude",
        // 	"vat_no": "333",
        // 	"address": "4 long road",
        // 	"id": -1,
        // 	"contact_person": "Calvin",
        // 	"company_reg": "2222",
        // 	"contact_no": "+27 098",
        // 	"rep_user_id": 4,
        // 	"customer_no": "1",
        // 	"type": "stockist",
        // 	"email": "calvin@diversitude.com"
        // },
        //, }
        form: {
            id: -1,
            company_name: null,
            type: null,
            contact_person: null,
            contact_no: null,
            company_reg: null,
            vat_no: null,
            customer_no: null,
            address: null,
            email: null,
            rep_user_id: null,
            city: null,
            province: null,
            postal_code: null,
        },
    }),
    validations: {
        form: {
            email: {
                required,
            },
            company_name: {
                required,
                minLength: minLength(3),
            },
            type: {
                required,
            },
            contact_no: {
                required,
                minLength: minLength(3),
            },
            contact_person: {
                required,
                minLength: minLength(3),
            },
            city: {
                required,
            },
            province: {
                required,
            },
            postal_code: {
                required,
            },
        },
    },
    computed: {
        ...repComputed,
        client_type_select_options() {
            // need to confirm the details here with api config

            return [
                {
                    value: null,
                    text: "Please select an option",
                    disabled: true,
                },
                { value: "Stockist", text: "Stockist" },
                { value: "Reseller", text: "Reseller" },
                { value: "Individual", text: "Individual" },
            ];
        },
        client_province_select_options() {
            // need to confirm the details here with api config

            return [
                {
                    value: null,
                    text: "Please select an option",
                    disabled: true,
                },
                { value: "Western Cape", text: "Western Cape" },
                { value: "Eastern Cape", text: "Eastern Cape" },
                { value: "Free State", text: "Free State" },
                { value: "Gauteng", text: "Gauteng" },
                { value: "KwaZulu-Natal", text: "KwaZulu-Natal" },
                { value: "Limpopo", text: "Limpopo" },
                { value: "Mpumalanga", text: "Mpumalanga" },
                { value: "North-West", text: "North-West" },
                { value: "Northern-Cape", text: "Northern-Cape" },
            ];
        },
    },
    methods: {
        ...repMethods,
        validateState(name) {
            if (this.formSubmit) {
                const { $dirty, $error } = this.$v.form[name];
                return $dirty ? !$error : null;
            }
        },
        closeModal() {
            this.$bvModal.hide("repCreateclientModal");
        },
        createClientForm() {
            this.formSubmit = true;

            this.$v.form.$touch();
            if (this.$v.form.$anyError) {
                return;
            }
            //eslint-disable-next-line
            console.log("form values", this.form);

            this.busySaving = true;

            this.createRepClient(this.form).then(() => {
                //setClients
                this.setSelectedRepDetails(this.$route.params.repid).then(() => {
                    this.busySaving = false;
                    this.$bvModal.hide("repCreateclientModal");
                    this.$bvModal.hide("repCreateclientModal");
                    Swal.fire("Completed", `Client was added`, "success");
                });
            });
        },
    },
    mounted() {
        //get the current rep user id from the url
        this.form.rep_user_id = this.$route.params.repid;
    },
};
</script>
