<template>
    <b-modal size="lg" :title="title" hide-footer id="assignClientModal">
        <div class="modal-body" style="width: 100%">
            <b-overlay :show="busySaving">
                <div v-if="hasMounted">
                    <webix-ui :config="ui" v-model="filterClients"></webix-ui>
                    <div id="linkPaging" class="linkpaging-children-width"></div>
                </div>

                <div class="modal-footer">
                    <b-button @click="onSubmit" v-if="edit && assignClients.length > 0" type="submit" variant="success">Submit</b-button>
                    <button type="button" class="btn btn-danger" @click="hideModal()">Cancel</button>
                </div>
            </b-overlay>
        </div>
    </b-modal>
</template>

<script>
import Swal from "sweetalert2";
import { repMethods, repComputed } from "@/state/helpers";
//eslint-disable-next-line
import lodash from "lodash";

export default {
    data: () => ({
        edit: false,
        assignClients: [],
        rep_id: null,
        rep_name: null,
        client_id: null,
        client_name: null,
        components: {},
        chosen: false,
        hasMounted: false,
        busySaving: false,
        ui: {
            id: "retableview",
            view: "datatable",
            css: "datatableUsers webix_header_border webix_data_border",
            autoheight: true,
            //hover: "datatableHover",
            minHeight: 60,
            minColumnWidth: 120,
            rowHeight: 40,
            scrollY: false,
            scrollX: true,
            resizeColumn: true,
            select: false,
            fillspace: true,
            maxHeight: 2000,
            pager: {
                template: "{common.prev()} {common.pages()} {common.next()}",
                container: "linkPaging",
                css: { width: "100% !important" },
                size: 15,
                group: 5,
            },
            columns: [
                {
                    id: "company_name",
                    header: [
                        "Client",
                        {
                            content: "textFilter",
                            placeholder: "client search",
                        },
                    ],

                    tooltip: false,
                    fillspace: 4,
                },

                {
                    checked: "checked",
                    // header: [{ content: "masterCheckbox" }],
                    header: { text: "Assign", css: { "text-align": "center" } },
                    template: "{common.checkbox()}",
                    width: 50,
                    css: { "text-align": "center" },
                },
            ],
            on: {
                //eslint-disable-next-line
                onCheck: function (type, message, args) {
                    //eslint-disable-next-line
                    const getId = type;
                    //eslint-disable-next-line
                    const el = this.$scope.$parent.$parent.$parent.$parent;

                    //when checkbox is checked
                    if (args == 1) {
                        // indexof returns -1 if no items match
                        if (el.assignClients.indexOf(getId) !== -1) {
                            //eslint-disable-next-line
                        } else {
                            el.assignClients.push(getId);
                            el.edit = true;
                        }
                    }
                    //when checkbox is unchecked
                    if (args == 0) {
                        if (el.assignClients.indexOf(getId) !== -1) {
                            el.assignClients = el.assignClients.filter((e) => e !== getId);
                        }
                    }
                },
            },
        },
    }),
    computed: {
        ...repComputed,
        title() {
            return `Assign a clients to: ${this.selected_rep_details.name}`;
        },
        filterClients() {
            let arrayOfAssignedCustomers = [];

            for (let k = 0; k < this.selected_rep_details.rep_clients.length; k++) {
                arrayOfAssignedCustomers.push(this.selected_rep_details.rep_clients[k].id);
            }

            let filteredClientsArray = [];

            this.all_clients.map((objectInArray) => {
                let flag = false;

                for (let i = 0; i < arrayOfAssignedCustomers.length; i++) {
                    if (objectInArray.id == arrayOfAssignedCustomers[i]) {
                        flag = true;
                        break;
                    }
                }
                if (flag === false) {
                    filteredClientsArray.push(objectInArray);
                }
            });

            return filteredClientsArray;
        },
    },
    watch: {
        hasMounted() {
            this.$nextTick();
        },
    },
    methods: {
        ...repMethods,
        hideModal() {
            this.$bvModal.hide("assignClientModal");
        },
        onSubmit() {
            this.busySaving = true;

            const currentClientIds = this.selected_rep_details.rep_clients.map((element) => {
                return element.id;
            });
            const repId = this.selected_rep_id;

            //merge arrays
            const clients = [...currentClientIds, ...this.assignClients];

            //pass clients array and the rep users ID to createToClientLink Method
            this.createRepToClientLink({
                client_ids: clients,
                rep_user_id: repId,
            }).then(() => {
                this.setClients().then(() => {
                    this.setSelectedRepDetails(this.selected_rep_id);
                    this.busySaving = false;
                    this.$bvModal.hide("assignClientModal");
                    Swal.fire("Completed", `Clients where assigned to the rep`, "success");
                });
            });
        },
    },
    mounted() {
        this.setClients();
        window.retableview = this;
        this.hasMounted = true;

        this.rep_id = this.selected_rep_id;
        this.rep_name = this.selected_rep_details.name;
        this.client_id = null;
    },
    beforeDestroy() {
        window.retableview = undefined;
    },
};
</script>

<style></style>
