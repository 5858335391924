<template>
    <div v-if="selected_rep_details">
        <div v-if="selected_rep_clients.length > 0">
            <webix-ui
                :config="ui"
                v-model="selected_rep_details.rep_clients"
            ></webix-ui>
            <div id="repClientsPaging"></div>
        </div>
        <h3 v-else class="text-center">This rep has no assigned clients yet</h3>
        <repsViewClientModal v-if="show_modal" />
    </div>
</template>

<script>
import Swal from "sweetalert2";
// import store from "@/state/store";
import { adminComputed, repMethods, repComputed } from "@/state/helpers";

export default {
    // components: { repsViewClientModal },
    data: () => ({
        assignClients: [],
        show_modal: false,
        ui: {
            id: "clientsTable",
            view: "datatable",
            css: "datatableUsers webix_header_border webix_data_border",
            autoheight: true,
            //hover: "datatableHover",
            minHeight: 60,
            minColumnWidth: 120,
            rowHeight: 40,
            scrollY: false,
            scrollX: true,
            resizeColumn: true,
            select: false,
            pager: {
                template: "{common.prev()} {common.pages()} {common.next()}",
                container: "repClientsPaging",
                size: 15,
                group: 5,
            },
            columns: [
                {
                    id: "company_name",
                    header: ["Company Name", { content: "textFilter" }],
                    tooltip: false,
                    fillspace: 1,
                },
                {
                    id: "last_order_date",
                    header: ["Last Order Date", { content: "textFilter" }],
                    sort: "string",
                    tooltip: false,
                    width: 180,
                },
                {
                    id: "type",
                    header: ["Type", { content: "selectFilter" }],
                    tooltip: false,
                    fillspace: 0.8,
                },
                {
                    id: "address",
                    header: ["Address", { content: "textFilter" }],
                    fillspace: 1.5,
                },
            ],
            onClick: {
                //eslint-disable-next-line
                viewAction: function (id, e, trg) {
                    //this method should create a new route to view the current client

                    const el = this.$scope.$parent;

                    const idForUrl = e.row;

                    el.$router.push({
                        path: `/reps/view/client/${idForUrl}`,
                        param: { idForUrl },
                    });
                },
                //eslint-disable-next-line
                removeAction: function (id, e, trg) {
                    const el = this.$scope.$parent;
                    const item = this.getItem(e.row);

                    const repId = el.selected_rep_id;

                    //resets the reference, and allows assingClients to be made an array again
                    el.assignClients = null;
                    el.assignClients = [];

                    Swal.fire({
                        title: `Remove "${el.selected_rep_details.name}" as a rep for "${item.company_name}"?`,
                        text: "You won't be able to undo this!",
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#34c38f",
                        cancelButtonColor: "#f46a6a",
                        confirmButtonText: "Yes, remove!",
                    }).then((result) => {
                        if (result.value) {
                            for (
                                let i = 0;
                                i < el.selected_rep_details.rep_clients.length;
                                i++
                            ) {
                                if (
                                    el.selected_rep_details.rep_clients[i]
                                        .id !== item.id
                                ) {
                                    el.assignClients.push(
                                        el.selected_rep_details.rep_clients[i]
                                            .id
                                    );
                                }
                            }
                            const clients = el.assignClients;

                            //passing the current clienst in the table, except the one that clicked on the the createRepToClientLink to send off the entire array of clients linked to this rep
                            el.createRepToClientLink({
                                client_ids: clients,
                                rep_user_id: repId,
                            }).then(() => {
                                el.setSelectedRepDetails(
                                    el.$route.params.repid
                                ).then(() => {
                                    Swal.fire(
                                        "Completed",
                                        `"${item.company_name}" was removed from rep "${el.selected_rep_details.name}"`,
                                        "success"
                                    );

                                    el.setFilteredSelectedRepDetails();
                                });
                            });
                        }
                    });
                },
            },
        },
    }),
    computed: {
        ...adminComputed,
        ...repComputed,

        // return ;§
        // return "";
        // return [];
    },
    methods: {
        ...repMethods,
    },
    beforeMount() {
        this.ui.columns.unshift({
            id: "actions",
            header: [
                {
                    text: "Actions",
                    rowspan: "2",
                    css: { "text-align": "center" },
                },
            ],
            fillspace: 0.1,
            template: () => {
                //eslint-disable-next-line
                const userId = this.loggedIn_user_role;

                //1 = admin
                if (userId == 1) {
                    return '<div class="tableIcons"><i  class=" mdi mdi-briefcase-remove removeAction "</i>&nbsp;</i><i class="mdi mdi-magnify-scan viewAction"></i></div>';
                }

                return '<div class="tableIcons"></i><i class="mdi mdi-magnify-scan viewAction"></i></div>';
            },
        });
    },
    mounted() {},
    beforeCreate() {
        window.inst = this;
    },
    beforeDestroy() {
        window.inst = undefined;
    },
};
</script>

<style></style>
